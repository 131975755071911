import { debounce } from '@mui/material'
import { useEffect, useMemo, useRef } from 'react'

export const useDebounce = (callback: Function) => {
  const ref = useRef<any>({})

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return debounce(func, 300)
  }, [])

  return debouncedCallback
}
